.our-team-container {}
.our-team-row {
  @include flexbox();
  @include flex-wrap(wrap);
  margin-right: 0;
  margin-left: 0;
  @include flex-direction(row);
  max-width: 100%;
  width: auto;
  @include justify-content(center);
  margin: auto;
}
.our-team-col {
  @include flexbox();
  @include flex-direction(column);
  max-width: none !important;
  @media (min-width: 576px) {
    @include flex(0 0 50%);
  }
  @media (min-width: 768px) {
    @include flex(1 1 33.33333%);
  }
  @media (min-width: 992px) {
    @include flex(1 1 25%);
  }
}
.team-element {
  border: 1px solid #ccc;
  padding: 30px 10px;
  margin: 15px auto;
  color: $secondary-color;
  max-width: none;
  img {
    vertical-align: baseline;
  }
  p {
    line-height: 15px;
  }
  a {
    text-decoration: none;
  }
  .team-element__name {
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: $font-family-regular;
  }
  .team-element__job {
    font-size: 1.2rem;
    font-family: $font-family-sans-serif;
  }
  .team-element__email {
    display: block;
    margin-bottom: 10px;
    font-family: $font-family-sans-serif;
  }
  .team-element__tel {
    display: block;
    color: $primary-color;
  }
}