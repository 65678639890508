.navigation {
	text-align: center;
}

.pagination {
	margin: 0 0 40px 0;
	//justify-content: center;
	display: block;
	span {
		margin: 2px;
		//margin-bottom: 0;
	}
}

.pagination>.page-item.active>a,
.pagination>.page-item.active>a:focus,
.pagination>.page-item.active>a:hover,
.pagination>.page-item.active>span,
.pagination>.page-item.active>span:focus,
.pagination>.page-item.active>span:hover {
	background-color: $bg-btn-primary;
	//box-shadow: 0 0 0 0;
	box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
	border-radius: $border-radius-circle;
	border-color: $bg-btn-primary;
	color: $text-color-btn-primary;
}

.pagination>.page-item.disabled>a,
.pagination>.page-item.disabled>a:focus,
.pagination>.page-item.disabled>a:hover,
.pagination>.page-item.disabled>span,
.pagination>.page-item.disabled>span:focus,
.pagination>.page-item.disabled>span:hover {
	border-color: $border-color;
	border-radius: $border-radius-circle;
	box-shadow: 0 0 0 0;
	color: $text-color;
	opacity: .5;
}

.pagination>li {
	display: inline-block;
	padding-left: 0;
	margin-right: 13px;
	&:before {
		content: none;
	}
}

.pagination>li.page-item:first-child>a,
.pagination>li.page-item:first-child>span,
.pagination>li.page-item:last-child>a,
.pagination>li.page-item:last-child>span {
	border-bottom-left-radius: $border-radius-circle;
    border-top-left-radius: $border-radius-circle;
	border-bottom-right-radius: $border-radius-circle;
    border-top-right-radius: $border-radius-circle;
}

.pagination>li.page-item>a,
.pagination>li.page-item>span {
	background-color: transparent;
	border-color: $border-color;
	border-radius: $border-radius-circle;
	border-width: 1px;
	color: $text-color;
	font-size: 1.8rem;
	font-family: $font-family-regular;
	padding: 0;
	line-height: 50px;
	text-align: center;
	width: 50px;
	height: 50px;
	text-decoration: none;
	box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
	&:hover, &:focus {
		background-color: $bg-btn-primary-hover;
	    border-color: $bg-btn-primary-hover;
		box-shadow: 0 0 0 0;
	    color: $text-color-btn-primary-hover;
	}
}

.pagination>.disabled>span {
	background-color: transparent;
}

.pagination li i {
	font-size: 1.4rem;
}