.section-presentation {
  background-color: $inverse-color;
  p {
    line-height: 28px;
    font-family: $font-family-sans-serif;
  }
  .who {
    background: url(../img/img-presentation.jpg) no-repeat;
    background-size: cover;
  }
  .presentation--who {
    background-color: $inverse-color;
    padding: 40px;
    margin: 50px 10px;
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
    text-align: center;

  }
  .presentation--art {
    text-align: center;
    margin-bottom: 50px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
      margin-top: 50px;
    }
    h2 {
      color: $inverse-color;
      font-size: 2.5rem;
      line-height: 34px;
      text-transform: uppercase;
    }
    p {
      color: $inverse-color;
    }
  }
  .presentation--vente-sur-place {
    padding-top: 20px;
    padding-bottom: 30px;

  }
}