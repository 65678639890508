.modal-header {
  border-bottom: none;
  justify-content: space-around;
  .close {
    display: none;
  }
}

.modal-footer {
  border-top: none;
  justify-content: center;
  padding-bottom: 3rem;
}

.modal-body  {
  h2 {
    margin-bottom: 40px;
  }
  h3 {
    color: $secondary-color;
    text-transform: uppercase;
    text-align: left;
    margin-top: 20px;
  }
  h4 {
    color: $secondary-color;
    text-align: left;
    margin-top: 10px;
  }
  p {
    line-height: 2rem;
    text-align: left;
    margin-left: 10px;
  }
}