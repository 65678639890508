/* Forms */
.header-form {
  background: url("../img/blue-pattern.jpg") repeat-x #fff;
  color: $inverse-color;
  text-transform: uppercase;
  text-align: center;
  padding: 30px;
  h3 {
    font-size: 3rem;
  }
}
.zone-form {
  background-color: $bg-block;
  border: 1px solid $bg-block;
  padding: 20px;
  margin-bottom: 20px;
  /*@include mq(sm) {
    padding: 45px 40px 30px 40px;
  }
  .small-h2 {
    margin-top: 0;
  }*/
}

label {
  font-weight: normal;
}
.form-group {
  margin: 0 0 30px 0;
  &.small-margin-bottom {
    margin-bottom: 15px;
    a {
      margin-bottom: 30px;
    }
  }
  p {
    margin-bottom: 0;
    text-align: left;
  }

  label, .label, legend {
    color: $secondary-color;
    font-family: $font-family-regular;
    font-size: 2rem;
    line-height: 24px;
    //margin-bottom: 15px;
    font-weight: normal;
    text-transform: uppercase;
  }

  .form-check-label {
    font-size: 1.6rem;
    font-family: $font-family-sans-serif;
    text-transform: inherit;
  }

  [type="file"] {
    color: $primary-color;
  }
}

.radio-btn-inline .form-check {
  display: inline-block;
  padding-top: 5px;
}

/*****************************************************************************************/



.form-control-feedback {
  font-size: 1.4rem;
  right: 6px;
}

.form-control,
.form-control[readonly] {
  background-color: $inverse-color;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  border-color: $border-color;
  border-radius: $border-radius-base;
  border-width: 1px;
  color: $text-color;
  font-family: $font-family-sans-serif;
  font-size: 1.8rem;
  height: 50px;
  line-height: normal;
  padding: 9px 16px;
  &:focus {
    border-color: $border-color;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.1);
    color: $text-color;
  }
  &::-moz-placeholder {
    color: $disabled-color;
    font-family: $font-family-sans-serif;
  }
  &::-webkit-input-placeholder {
    color: $disabled-color;
    font-family: $font-family-sans-serif;
  }
  &:-ms-input-placeholder  {
    color: $disabled-color;
    font-family: $font-family-sans-serif;
  }
}
/*

legend, .legend {
  color: $text-color;
  border: none;
  font-family: $font-family-regular;
  font-size: 3.2rem;
  line-height: 44px;
  margin-bottom: 30px;
}*/


textarea.form-control {
  height: 165px;
  resize: none;
}
/*
.form-check {
  margin: 0;
  padding-right: 30px;
  position: relative;
  label {
    font-size: 1.6rem;
    line-height: 20px;
    margin-bottom: 10px;
    padding-top: 0;
  }
  [type="checkbox"]:not(:checked) + label, [type="checkbox"]:checked + label {
    color: $text-color;
  }
  .form-control-feedback {
    top: -5px;
  }
}

.content-checkbox--single {
  label:last-child {
    margin-left: 0;
  }
}

.content-checkbox--multi {
  .form-group {
    margin: 0;
  }
  label {
    margin-bottom: 10px;
    margin-right: 35px;
    &:last-child {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
*/

/* CUSTOM INPUT FILE */
.zone-form ul {
  list-style-type: none;
  margin: 20px 0;
}
.zone-form li input.custom-file-input {
  opacity: 1;
}
.zone-form li label.custom-file-label {
  display: none;
}
.zone-form li > div> div.form-group {
  width: 50%;
  display: inline-block;
  margin: 0;
  &:first-of-type label {
    display: none;
  }
  .form-check {
    float: right;
    margin-top: 5px;
  }
}
.zone-form .form-group legend.col-form-label:not(.required) {
  display: none; //Suppression  de "wording" généré automatiquement...
}
/* custom checkbox *! */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
}


[type="checkbox"]:not(:checked) +label:before,
[type="checkbox"]:checked +label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 1px solid $border-color;
  background: $inverse-color;
  border-radius: 5px;
  box-shadow: inset 0 0px 0px rgba(0,0,0,0);
}
[type="checkbox"]:checked +label:before {
  background-color: $inverse-color;
  border-color: $primary-color;
}
[type="checkbox"]:not(:checked) +label:after,
[type="checkbox"]:checked +label:after {
  content: "\e918";
  font-family: 'icomoon';
  position: absolute;
  top: 1px;
  left: 5px;
  font-size: 1.5rem;
  color: $primary-color;
}

[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
}

[type="checkbox"]:disabled:not(:checked) +label:before,
[type="checkbox"]:disabled:checked +label:before {
  box-shadow: none;
  border-color: $inverse-color;
  background-color: transparent;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}

//Custom checkbox required
[type="checkbox"][required="required"]:not(:checked):required,
[type="checkbox"][required="required"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"][required="required"]:not(:checked) + i + label,
[type="checkbox"][required="required"]:not(:checked) + i + .tooltip + label,
[type="checkbox"][required="required"]:checked + i + label,
[type="checkbox"][required="required"]:checked + i + .tooltip + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
}

[type="checkbox"][required="required"]:not(:checked) + i + label:before,
[type="checkbox"][required="required"]:not(:checked) + i + .tooltip + label:before,
[type="checkbox"][required="required"]:checked +i + label:before,
[type="checkbox"][required="required"]:checked +i + .tooltip + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 1px solid $border-color;
  background: transparent;
  border-radius: 5px;
  box-shadow: inset 0 0px 0px rgba(0,0,0,0);
}
[type="checkbox"][required="required"]:checked + i + label:before {
  background-color: $primary-color;
  border-color: $primary-color;
}
[type="checkbox"][required="required"]:not(:checked) + i + label:after,
[type="checkbox"][required="required"]:checked + i + label:after {
  content: "\e918";
  font-family: 'icomoon';
  position: absolute;
  top: 1px;
  left: 5px;
  font-size: 1rem;
  color: $inverse-color;
}

[type="checkbox"][required="required"]:not(:checked) + i + label:after,
[type="checkbox"][required="required"]:not(:checked) + i + .tooltip + label:after {
  opacity: 0;
}
[type="checkbox"][required="required"]:checked + i + label:after,
[type="checkbox"][required="required"]:checked + i + .tooltip + label:after  {
  opacity: 1;
}

[type="checkbox"][required="required"]:disabled:not(:checked) +i +label:before,
[type="checkbox"][required="required"]:disabled:checked +i +label:before {
  box-shadow: none;
  border-color: $inverse-color;
  background-color: transparent;
}
[type="checkbox"][required="required"]:disabled:checked + i +label:after {
  color: #999;
}
[type="checkbox"][required="required"]:disabled + i +label {
  color: #aaa;
}


/*
!* custom radio *!*/
input[type="radio"]:not(:checked),
input[type="radio"]:checked {
  position: absolute;
  left: -9999px;
}

input[type="radio"]:not(:checked) + label,
input[type="radio"]:checked + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
}

input[type="radio"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 0;
  width: 26px;
  height: 26px;
  border: 1px solid $border-color;
  background-color: $inverse-color;
  border-radius: 50%;
}
input[type="radio"]:checked + label:before {
  background-color: $inverse-color;
  border-color: $primary-color;
}

input[type="radio"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: $primary-color;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: all .2s;
}

input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="radio"]:checked + label:after {
  opacity: 1;
}

// Form validation
.has-danger {
  .fv-help-block{
    color: $error-color;
    font-family: $font-family-sans-serif;
    font-size: 1.3rem;
    padding-top: 3px;
  }
}

.has-success {
  .is-valid {
    border-color: $border-color;
  }
  .form-check-input.is-valid ~ .form-check-label {
    color: $secondary-color;
  }
}


/*
.has-error {
  .tooltip {
    &.bottom .tooltip-arrow {
      border-top-color: $error-color;
      border-bottom-color: $error-color;
    }
  }
  .tooltip-inner {
    background-color: $error-color;
  }
  .form-control-feedback, .control-label {
    color: $error-color;
  }
  .form-control {
    border-color: $error-color;
    box-shadow: 0 0 0 0;
    color: $error-color;
    &:focus {
      border-color: $error-color;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--multiple {
    border-color: $error-color;
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $error-color;
  }
  .select2-container--default.select2-container--focus .select2-selection--multiple,
  .select2-container--default.select2-container--focus {
    border-color: $error-color;
  }
  [type=checkbox]:not(:checked)+label:before,
  [type=checkbox][required=required]:not(:checked)+i+label:before,
  [type=checkbox][required=required]:not(:checked)+i+div+label:before{
    border-color: $error-color;
  }

  input[type="radio"]:not(:checked) + label:before,
  input[type="radio"]:checked + label:before {
    border-color: $error-color;
  }
  .help-block {
    color: $error-color;
    li {
      padding: 0;
      &:before {
        content: none;
      }
    }
  }
  .input-group-addon {
    background-color: transparent;
    border-color: transparent;
    color: $error-color;
  }
  .btn.disabled {
    opacity: 0;
  }
}

.has-feedback label.sr-only~.form-control-feedback {
  top: 5px;
  right: 0;
}

.has-feedback label~.form-control-feedback {
  top: 50px;
}


.has-success {
  color: $text-color;
  .form-control-feedback, .control-label {
    color: $text-color;
  }
  .form-control {
    border-color: $border-color;
    box-shadow: 0 0 0 0;
    color:  $text-color;
    &:focus {
      border-color: $border-color;
      box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.1);
      color: $text-color;
    }
  }
  &.form-group--rdv .form-control {
    border-color: $border-color;
  }
  .select2-container--default .select2-selection--single,
  .select2-container--default .select2-selection--multiple {
    border-color: $border-color;
  }
  .btn.btn-primary {
    z-index: 5;
  }

  label + .input-group {
    .input-group-addon {
      display: none;
    }
  }
}

.has-feedback {
  .form-control {
    padding-right: 10px;
  }
  label.sr-only~.form-control-feedback {
    top: 5px;
    right: 0;
  }
}
*/


/*
.alert {
  padding: 0;
  i {
    display: block;
    float: left;
    font-size: 1.2rem;
    margin-right: 7px;
    margin-top: 6px;
  }
  p {
    color: $text-color;
    font-size: 1.6rem;
    display: block;
    margin-bottom: 0;
  }
  &.alert-danger {
    background-color: transparent;
    border-color:transparent;
    color: $error-color;
  }
  &.alert-success {
    background-color: transparent;
    border-color: transparent;
    color: $primary-color;
  }
  &.alert-info {
    background-color: transparent;
    border-color: transparent;
    color: $primary-color;
  }
}
*/