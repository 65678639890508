.address {
  text-align: center;
  font-family: $font-family-sans-serif;
  h2 {
    color: $secondary-color;
    font-family: $font-family-regular;
    font-size: 2rem;
    margin: 0;
  }
  span {
    display: block;
    font-size: 1.4rem;
  }
  a {
    display: block;
  }
  .address-tel {
    color: $primary-color;
    font-family: $font-family-regular;
    text-transform: uppercase;
    text-decoration: none;
  }
}