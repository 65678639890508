

.section-small {
  padding: 15px 0;
}
.section-default {
  padding: 25px 0 40px;
  @include media-breakpoint-down(xs) {
    padding: 15px 0 30px;
  }
}
.section-big {
  padding: 50px 0;
  @include media-breakpoint-down(xs) {
    padding: 30px 0;
  }
}

.section-borders {
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  padding: 20px 0 10px;
  a {
    text-decoration: none;
    font-family: $font-family-sans-serif;
    color: $secondary-color;
    @include media-breakpoint-down(sm) {
      span {
        display: none;
      }
    }
  }
  .double-btn {
    justify-content: center;
  }
}

.bg-grey-section {
  background-color: $bg-grey-section;
}
.bg-grey-darken-section {
  background-color: $bg-grey-darken-section;
}

.bg-white-section {
  background-color: $inverse-color;
}