//@import "~animatewithsass/_animate";

@mixin mq($breakpoint) {
  $mq-allphone: "(max-width: 440px)";
  $mq-xs: "(max-width: 767px)";
  $mq-sm: "(min-width: 768px)";
  $mq-md: "(min-width: 992px)";
  $mq-lg: "(min-width: 1200px)";

  @if $breakpoint == allphone {
    @media #{$mq-allphone} { @content; }
  }
  @if $breakpoint == xs {
    @media #{$mq-xs} { @content; }
  }
  @else if $breakpoint == sm {
    @media #{$mq-sm} { @content; }
  }
  @else if $breakpoint == md {
    @media #{$mq-md}  { @content; }
  }
  @else if $breakpoint == lg {
    @media #{$mq-lg}  { @content; }
  }
}

@mixin background-opacity($color, $opacity) {
  background: $color; 
  background: rgba($color, $opacity);
}

@mixin transition($property) {
  -ms-transition: $property;
  -webkit-transition: $property;
  transition: $property;
}

@mixin transition-property($property...) {
  -webkit-transition-property: -webkit-$property;
  transition-property: $property;
}

@mixin transition-duration($property...) {
  -webkit-transition-duration: $property;
  transition-duration: $property;
}

@mixin transition-timing-function($timing...) {
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

@mixin transform($property) {
	-webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
    transform: $property;
}

@mixin rotate($rotate) {
  -moz-transform:rotate($rotate deg); //firefox
  -webkit-transform:rotate($rotate deg); //Chrome, Safari
  -o-transform:rotate($rotate deg); //Opera
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}


@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}

@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	-moz-align-items: $value;
  -ms-align: $value;
	align-items: $value;
}


@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
    
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
  -moz-box-ordinal-group: $val;     
  -ms-flex-order: $val;     
  -webkit-order: $val;  
  order: $val;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;    
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

  