.section-view-sale {
  background: $bg-grey-section;
  padding: 30px 20px;
}

.section-view-sale--img-bckgr {
  border: 1px solid $border-color;
  min-height: 200px;

}

.section-view-sale--main-info {
  background: $inverse-color;
  border: 1px solid $border-color;
  border-left: none;
  @include media-breakpoint-down(sm) {
    border: 1px solid $border-color;
    border-top: none;
  }

  h1 {
    padding: 60px 20px 50px 20px;
    @include media-breakpoint-down(sm) {
      padding: 20px 20px 10px 20px;
    }
  }
  .section-view-sale--main-info__details {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    align-content: stretch;
    border-top: 1px solid $border-color;
    font-family: $font-family-sans-serif;
    @include media-breakpoint-down(sm) {
      display: block;
      > div {
        &:first-of-type{
          border-right: none!important;
          border-bottom: 1px solid $border-color;
        }
        br {
          display: none;
        }
      }
    }
    > div {
      padding: 20px 20px 10px 20px;
      &:first-of-type{
        border-right: 1px solid $border-color;
      }
    }
    a {
      text-decoration: none;
    }
    .section-view-sale--main-info__details__date {
      font-family: $font-family-regular;
      color: $primary-color;
    }
  }
}

.section-view-sale--details {
  border-bottom: 1px solid $border-color;
  p {
    line-height: 28px;
    font-family: $font-family-sans-serif;
  }


  .btn {
    display: inline-block;
    margin: 5px 5px 10px 5px;
  }
}