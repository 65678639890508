footer {
  padding: 40px 0;
  text-align: center;
  font-family: $font-family-sans-serif;
  background-color: $bg-footer;
  color: $text-footer;

  a {
    color: $text-footer;
    &:hover{
      color: $inverse-color;
    }
    i {
      font-size: 5rem;
    }
  }
  img {

  }
}
.footer_blog-link {
  text-decoration: none;
  font-family: $font-weight-bold;
}