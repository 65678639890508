//Variables couleurs
$primary-color: #122c6c;
$secondary-color: #111111;


// Text colors
$title-color: $secondary-color;
$text-color: #909090;
$text-footer: #444;
$grey-text: #737373;
$grey-secondary: #888;
$inverse-color: #fff;

$disabled-color: #ccc;


// Background colors
//$bg-body: #e8e8e8;
$bg-body: #fff;
$bg-block: #eee;
$bg-grey-section: #e9e9e9;
$bg-grey-darken-section: #ddd;
$bg-white-section: #fff;
$bg-header: $inverse-color;
$bg-footer: #080808;


// Btns
$bg-btn-primary: $primary-color;
$bg-btn-primary-hover: #2c4686;
$text-color-btn-primary: $inverse-color;
$text-color-btn-primary-hover: $inverse-color;
$btn-primary-hover-bg-color: lighten( $primary-color, 10% );
$bg-btn-default: #faf4f4;


// Borders
$border-color: #ccc;
$table-border-color: $border-color;
$border-radius-base: 5px;
$border-radius-circle: 25px;

// Form validation
$error-color: #c5393a;


// Cookie Banner
$bg-cookie-banner : #000;


// Fonts
$font-family-regular: 'Source Serif Pro', serif;
$font-family-sans-serif : 'Source Sans Pro', sans-serif;