.section-view-sale-has-product {
  padding: 30px 0;
  p {
    margin-bottom: 2rem;
  }
    .section-view-sale-has-product--rank {
        color: $secondary-color;
    }
    .section-view-sale-has-product--estimation {
      font-size: 2.2rem;
        span {
            color: $primary-color;
            font-family: $font-family-regular;
        }
    }
  .section-view-sale-has-product--ask-expertise {
    @include media-breakpoint-down(sm) {
      text-align: center;
      }
    }

  .owl-carousel--view_saleHasProduct-exceptional {
    position: relative;
      .sale-exception--tag {
        right: 20px;
        top: 20px;
        z-index: 999;
      }

      .owl-stage-outer .active {
        border: 1px solid $secondary-color;
      }
  }
}
button.lg-icon{
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
.categorie-tag {
    background-color: $bg-block;
    padding: 5px 10px;
    border-radius: 500px;
    font-family: $font-family-sans-serif;
    font-size: 12px;
}