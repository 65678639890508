.item-sale {
  display: flex;
  flex-direction: column;
  height: 100%;
  &:hover, &:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, .45);
  }
  .item-sale-tag {
    margin-top: -25px;
    z-index: 999;
    width: 60px;
    text-align: center;
  }
}

.item-sale-tag {
  background-color: $primary-color;
  padding: 5px 10px;
  border-radius: 500px;
  font-family: $font-family-sans-serif;
  font-size: 12px;
  color: $inverse-color;
  vertical-align: text-top;
}

.item-sale__main-content {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  h3 {
    //padding: 10px 15px;
  }
}

.item-sale__content__title {
  text-transform: uppercase;
  a {
    color: $title-color;
    line-height: 30px;
    display: block;
    margin: 0;
    text-decoration: none;
    width: 100%;
    text-align: center;
    span {
      display: block;
      line-height: normal;
    }
    &.item-sale__content__version {
      color: $grey-secondary;
      font-size: 1.2rem;
      line-height: 30px;
      &:hover, &:focus {
        color: $grey-secondary;
      }

    }
    &:hover, &:focus {
      color: $title-color;
    }
  }
}

.item-sale__content__details {
  display: flex;
  border-top: 1px solid $border-color;
  align-items: center;
  font-family: $font-family-sans-serif;
  @include media-breakpoint-down(sm) {
    display: block;
  }

  .item-sale__content__details--date{
    padding: 10px;
    color: $primary-color;
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    @include media-breakpoint-down(sm) {
      border-right: none;
    }
  }
  .item-sale__content__details--lieu{
    padding: 10px;
    color: $text-color;
    border-right: 1px solid $border-color;
    @include media-breakpoint-down(sm) {
      border-right: none;
    }
  }
  .item-sale__content__details--lots{
    text-align: center;
    color: $title-color;
    padding: 10px 20px;
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
      border-top: 1px solid $border-color;
      > div {
        margin-right: 5px;
      }
    }
  }
}

.item-sale__content__details .no-details-lots {
  .item-sale__content__details--date,
  .item-sale__content__details--lieu {
    border-right-width: 0;
  }
}