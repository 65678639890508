.section-tabs {
  background: $bg-grey-darken-section;
  a {
    text-decoration: none;
    text-transform: uppercase;
  }
  [class^="col"]{
    padding: 15px;
  }
  .section-tabs--active {
    background: $bg-white-section;
    color: $secondary-color;
    a {
      color: $secondary-color;
    }
  }
}