
.rte-content {
  ul {
    font-family: $font-family-sans-serif;
    list-style-type: none;
    li {
      &:before {
      content: '\e918';
      font-family: 'icomoon';
      font-size: 1rem;
      }
      p {
        margin-bottom: 0;
        margin-left: 5px;
        display: inline-block;
      }
    }
  }
  strong {
    color: $secondary-color;
  }
  h3 {
    color: $secondary-color;
    font-size: 2rem;
  }
  div:last-of-type {
    h3 {
      color: $primary-color;
    }
  }
  p.paragraphe-large {
    font-size: 2.5rem;
  }
}