html, body {
  margin: 0;
  -webkit-backface-visibility: hidden;
}

html { 
  font-size: 62.5%;
  height: 100%;
  margin-right: 0 !important;
  overflow: visible !important;
}

body { 
  //@include flexbox();
  //@include flex-direction(column);
  background-color: $bg-body;
  color: $text-color;
  font-family: $font-family-regular;
  font-size: 1.6rem;
  min-height: 100vh;
  overflow-x: hidden;
  margin: 0 auto;
  width: 100%;

  @include media-breakpoint-down(sm) {
    padding-top: 8rem;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
  line-height: normal;
}

span, li, p, a, h1, h2, h3, h4, h5, h6 {
  line-height: normal;
}

a { 
  @include transition(all 0.2s ease);
  color: $text-color;
  text-decoration: underline;

  &:hover, 
  &:focus {
    color: $secondary-color;
    cursor: pointer;
  }
}

h1 {
  color: $title-color;
  font-size: 4.2rem;
  line-height: 52px;
  text-transform: uppercase;
  margin: 0;
  padding:  30px 0;
  @include media-breakpoint-down(sm) {
    font-size: 2.2rem;
    line-height: 30px;
  }
}

h2 {
  color: $title-color;
  font-size: 3.6rem;
  line-height: 42px;
  text-transform: uppercase;
  margin: 15px 0;
  @include media-breakpoint-down(sm) {
    font-size: 2.0rem;
    line-height: 28px;
  }
}

h3 {
  text-transform: uppercase;
}

p {
  font-family: $font-family-sans-serif;
  line-height: 28px;
}

.section-main-title {
  background-color: $bg-grey-section;
}

.text-date {
  color: $primary-color;
  font-size: 2.4rem;
  font-family: $font-family-regular;
}

.h-100 {
  height: 100%; // hack IE Flexbox
}

/*Custom bandeau cookie consent */
.cc-window.cc-banner,
.cc-window.cc-floating {
  background-color: rgba($title-color, 0.9);
  color: #fff;
  font-family:  $font-family-sans-serif;
  font-size: 1.4rem;
  line-height: normal;

  a {
    color: #fff;
  }
  .cc-btn {
    @include transition(all 0.2s ease);
    border-width: 1px;
    font-family:  $font-family-sans-serif;
    font-weight: normal;
    line-height: 18px;
    padding: 6px 12px;
    text-decoration: none;
    &:hover, &:focus, &:active, &:active:hover {
      color: #fff;
      background-color: $text-color;
      border-color: $text-color;
    }
  }
  .cc-btn:last-child {
    min-width: auto;
  }
}
