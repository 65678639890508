header {
  background-color: $bg-header;
  .section-header {
    padding: 30px 20px 20px;
    text-align: center;
    border-bottom: 1px solid $border-color;
    @include media-breakpoint-down(sm) {
      position: fixed;
      width: 100%;
      z-index: 1;
      background-color: $bg-body;
    }
  }
  .social-links {
    a {
      color: $secondary-color;
      text-decoration: none;
      font-size: 2.5rem;
    }
  }
}