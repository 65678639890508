.section-nav {
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: $bg-white-section;
  }
  .navbar {
    .nav-item {
      padding: 10px;
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
  }
}
.navbar-nav {
  width: 100%;
}

.navbar-primardeco {
  align-items: initial;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
  .navbar-primardeco--header {
    height: 8rem;
    padding: 20px 0 15px;
    border-bottom: 1px solid $border-color;
    width: 100%;
    .navbar-brand {
      margin-right: 0;
    }
    .navbar-primardeco--header__tel {
      padding: 0.25rem 0.75rem;
    }
  }
  a {
    text-decoration: none;
    font-family: $font-family-sans-serif;
    &.nav-link {
      color: $primary-color;
      @include media-breakpoint-down(sm) {
        padding: 10px;
      }
    }
  }
  .dropdown-menu {
    background-color: $primary-color;
    border: none;
    border-radius: 0;
    color: $inverse-color;
    font-size: 1.6rem;
    margin-top: 0;
    &::before {
      display: none;
    }
    .dropdown-item {
      color: $inverse-color;
      padding: 10px 20px 10px 15px;
      i {
        font-size: .9rem;
        color: $border-color;
      }
      &:hover {
        color: $primary-color;
        background-color: $inverse-color;
        opacity: 1;
      }
    }
  }
}