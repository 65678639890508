.item-askExpertise {
  color: $inverse-color;
  //background: url("../img/blue-pattern.jpg") repeat-x $primary-color;
  background-color: $primary-color;
  display:flex;
  div {
    height: 100%;
    display:flex;
    align-items:center;
    justify-content: center;
  }
  &:hover {
    color: $inverse-color;
  }
  li {
    padding: 10px 0;
    list-style:none;
    &::before {
      content: "\e917";
      font-family: 'icomoon';;
      margin-right: 10px;
      font-size: 1.5rem;
    }
  }
  .item-askExpertise--link {
    background-color: $primary-color;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    a {
      color: $inverse-color;
      text-align: center;
      text-decoration: none;
    }
    p {
      color: #ccc;
    }
  }
}