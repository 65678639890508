.expertises {
  //color: $inverse-color;
  background-color: $inverse-color;
  h2 {
    font-size: 2.4rem;
    color: $primary-color;
    margin: 0;
  }
  > div.d-none {
    background-size: cover;
    text-align: center;
    min-height: 0;
    @include media-breakpoint-up(sm) {
      min-height: 150px;
    }
  }
}

.section-expertise-category {
  padding: 30px 20px;
  .section-expertise-category--img-bckgr {
    border: 1px solid $border-color;
    min-height: 200px;
  }
  .section-expertise-category--main-info {
    padding: 50px 25px;
    border: 1px solid $border-color;
    border-left: none;
    @include media-breakpoint-down(sm) {
      border: 1px solid $border-color;
      border-top: none;
    }
  }
}