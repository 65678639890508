@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?taae8q');
  src:  url('../fonts/icomoon.eot?taae8q#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?taae8q') format('truetype'),
  url('../fonts/icomoon.woff?taae8q') format('woff'),
  url('../fonts/icomoon.svg?taae8q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-gplus:before {
  content: "\e903";
}
.icon-linkedin:before {
  content: "\e904";
}
.icon-youtube:before {
  content: "\e905";
}
.icon-pinmap:before {
  content: "\e906";
}
.icon-etoile:before {
  content: "\e907";
}
.icon-citation-g:before {
  content: "\e908";
}
.icon-citation-d:before {
  content: "\e909";
}
.icon-tel-contour:before {
  content: "\e90a";
}
.icon-menu:before {
  content: "\e90b";
}
.icon-croix:before {
  content: "\e90c";
}
.icon-chevron-d:before {
  content: "\e90d";
}
.icon-chevron-g:before {
  content: "\e90e";
}
.icon-chevrondouble-d:before {
  content: "\e90f";
}
.icon-chevrondouble-g:before {
  content: "\e910";
}
.icon-tel:before {
  content: "\e911";
}
.icon-chevron-b:before {
  content: "\e912";
}
.icon-chevron-h:before {
  content: "\e913";
}
.icon-plus:before {
  content: "\e914";
}
.icon-fermer:before {
  content: "\e915";
}
.icon-info:before {
  content: "\e916";
}
.icon-check:before {
  content: "\e917";
}
.icon-succes:before {
  content: "\e918";
}