//Custom Bootstrap carousel
.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.carousel-control {
	opacity: 1;
	text-shadow: 0 1px 2px rgba(0,0,0,.6);
	z-index: 5;
	&.right, &.left {
		background-image: none;
	}
	.glyphicon-chevron-right, .carousel-control .icon-next {
		right: 40%;
		@include media-breakpoint-down(sm) {
			right: 25%;
		}
		@include media-breakpoint-down(sm) {
			right: 15%;
		}
	}
	.glyphicon-chevron-left, .carousel-control .icon-prev {
		left: 40%;
		@include media-breakpoint-down(sm) {
			left: 25%;
		}
		@include media-breakpoint-down(sm) {
			left: 15%;
		}
	}
	.glyphicon-chevron-left,
	.glyphicon-chevron-right {
		@include media-breakpoint-down(sm) {
			font-size: 2.4rem;
		}
	}
}


.carousel-indicators {
	bottom: -40px;
	line-height: 0;
	z-index: 200000;
	@include media-breakpoint-down(sm) {
		position: absolute;
		bottom: 30px;
		left: 30px;
		line-height: 0;
		right: auto;
		width: auto;
		margin: 0;
		z-index: 200000;
	}
	li {
		border-color: #022a39;
		background-color: transparent;
		&:before {
			content: none;
		}
	}
	.active {
		background-color: $secondary-color;
		margin: 1px;
		width: 10px;
    	height: 10px;
	}
} 
.icon-fleche-g:before {
    content: "\e905";
    font-family: 'icomoon';
}
.icon-fleche-d:before {
    content: "\e904";
    font-family: 'icomoon';
}

//Custom Owl carousel 
.owl-carousel {
	margin-bottom: 30px;
	.owl-stage {
		display: flex;
		padding: 5px
	}
	.owl-item, .owl-item.cloned {
		.item {
			a {
				&:hover, &:focus {
					cursor: default;
				}
			}
		}
	}
	.owl-item.cloned {
		.item {
			a {
				&:hover, &:focus {
					cursor: not-allowed;
				}
			}
		}
	}
	.owl-item {
		flex: 1;
		//padding: 25px;
		@include media-breakpoint-down(sm) {
			padding: 0 0 25px 0;
		}
		@include media-breakpoint-down(md) {
			padding: 0;
		}
		&.active {
			.item {
				a {
					@include transition(all 0.2s ease);
					display: block;
					position: relative;
					&:hover, &:focus {
						cursor: pointer;
						.zoom {
							opacity: 1;
						}
					}
				}
			}
		}
		.item-card {
			margin-bottom: 0;
		}
	}
	// HACK pour le H3 de la HP
	&.owl-carousel--nextSale {
		.item-sale__main-content {
			padding: 18px 15px;
		}
	}
}


.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	margin-top: -6px !important; 
	text-shadow: 0 1px 2px rgba(0,0,0,.6);
	i {
		color: $inverse-color;
		font-size: 2rem;
		@include media-breakpoint-down(sm) {
			font-size: 2.4rem;
		}
	}
	&:hover, &:focus {
		i {
			color: $inverse-color;
		}
	}
	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
		&:hover, &:focus {
			color: $disabled-color;
			cursor: not-allowed;
			i {
				color: $disabled-color;
			}
		}
	}
	
}
.owl-prev {
	left: -15px;
	@include media-breakpoint-down(md) {
		left: 30px;
	}
	@include media-breakpoint-down(lg) {
		left: 50px;
	}
}
.owl-next {
	right: 0;
	@include media-breakpoint-down(md) {
		right: 30px;
	}
	@include media-breakpoint-down(lg) {
		right: 25px;
	}
}

// Styling dots
.owl-dots {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
	margin-top: 20px;

	button {
		border: 1px solid $border-color!important;
		border-radius: 30px;
		width: 20px;
		height: 20px;
		margin-right: 5px;
		background-color: $inverse-color;
	}

	.owl-dot {
	  display: inline-block;
	  zoom: 1;
	  *display: inline;

	  span {
	    width: 8px;
	    height: 8px;
		  margin: auto;
		  background: $primary-color;
		  //border-color: $primary-color;
		border: 1px solid $primary-color;
	    display: block;
	    -webkit-backface-visibility: visible;
	    transition: opacity 200ms ease;
	    border-radius: 30px;
		  opacity: .2;
	  }

	  &.active,
	  &:hover {
	    span {
	      background: $primary-color;
		  border-color: $primary-color;
			opacity: 1;
	    }
	  }
	}
}

// Custom Carousel "View saleHasProduct
.owl-carousel--view_saleHasProduct {
	margin-bottom: 0;
	.owl-stage-outer .active {
		border: 1px solid $border-color;
	}
	.owl-dots {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		margin: auto;
		button {
			background-color: $inverse-color;
		}

	}
}