.item-sale-has-product {
  display: flex;
  flex-direction: column;
  height: 100%;
  &:hover, &:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, .45);
  }
  &.sale-exception {
    border: 1px solid $secondary-color;
    position: relative;
  }
}

.sale-exception--tag {
  background-color: $secondary-color;
  font-size: 1.2rem;
  font-family: $font-family-sans-serif;
  color: $inverse-color;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 8px 10px;
  border-radius: $border-radius-base;
}

.item-sale-has-product__main-content {
  display: flex;
  align-items: center;
  background-color: transparent;
  position: relative;
  padding: 10px 15px 0px 15px;
}

.item-sale-has-product__content__title {
  margin-bottom: 10px;
  a {
    color: $title-color;
    font-size: 1.4rem;
    line-height: 30px;
    display: block;
    margin: 0;
    text-decoration: none;
    width: 100%;
    text-align: center;
    span {
      display: block;
      line-height: normal;
    }
    &:hover, &:focus {
      color: $title-color;
    }
  }
}

.item-sale-has-product__content__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-family-sans-serif;
  padding-bottom: 20px;
  .item-sale-has-product__content__details--lots{
    font-size: 1.2rem;
    color: $secondary-color;
  }
  .item-sale-has-product__content__details--categorie{
    background-color: $bg-block;
    border-radius: 1em;
    padding: 5px 10px;
    margin: 15px 10px;
    font-size: 1.4rem;
    color: $secondary-color;
    text-align: center;
  }
  .item-sale-has-product__content__details--price{
    color: $primary-color;
    font-family:  $font-family-regular;
  }
}