.accordion {
  .card {
    border-radius: 0;
    margin-bottom: 5px;
    border: 1px solid $border-color;
    .card-header {
      border-radius: 0;
      padding: 0;
    }
    &:first-of-type, &:last-of-type {
      border-bottom: 0;
    }
    &:not(:first-of-type) .show,
    &:not(:last-of-type) .show {
      border-bottom: 1px solid $border-color;
    }
  }
  .btn {
    width: 100%;
    text-align: left;
    font-family: $font-family-regular;
    font-size: 2rem;
    background-color: $bg-block;
    box-shadow: none;
    border: 0;
    white-space: normal;
    i {
      opacity: .2;
    }
  }
  .card-body {
    font-family: $font-family-sans-serif;
    padding: 20px 30px;
    line-height: 28px;
    font-size: 1.8rem;
  }
  button[aria-expanded="true"] i {
    display: inline-block;
    &.rotate-icon {
      transform: rotate(45deg);
    }
  }
  //button[aria-expanded="true"]
}