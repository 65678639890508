.categories {
  .container-fluid {
    padding: 0;
  }

  .myCol {
    flex: 1 0 20%;
    min-height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    @include media-breakpoint-down(xs) {
      min-height: 100px;
      flex: 1 0 100%;
    }
    @include media-breakpoint-down(md) {
      &:nth-child(5), &:nth-child(11) {
        display: none;
        border: 5px solid blue!important;
      }
    }
  }
}

.link-categories {
  color: $inverse-color;
  text-decoration: none;
  font-family: $font-family-sans-serif;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0,0,.6);
  @include transition(all 0.2s ease);
  &:hover {
    background-color: rgba(0, 0,0,.3);
    text-decoration: none;
    color: $inverse-color;
  }
}