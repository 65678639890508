.btn {
  @include transition(all 0.2s ease);
  border-width: 1px;
  font-family: $font-family-sans-serif;
  font-size: 1.4rem;
  line-height: 20px;
  padding: 15px 22px;
  text-decoration: none;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
  &:focus, &:active:focus {
    outline: none;
  }
  &:active {
    box-shadow: 0 0 0 0;
  }
}

.btn-default {
  background-color: $bg-btn-default;
  border-color: $border-color;
  color: $primary-color;
  border-radius: 500px;
  &:hover, &:focus, &:active, &:active:hover, &:active:focus {
    box-shadow: none;
    background-color: #f8f8f8;
  }
}

.btn-primary {
  color: $inverse-color;
  background-color: $primary-color;
  border-color: $primary-color;
  border-radius: 500px;
  &:hover, &:focus, &:active, &:active:hover, &:active:focus {
    background-color: $btn-primary-hover-bg-color;
    border-color: $btn-primary-hover-bg-color;
    box-shadow: none;
    color: $inverse-color;
  }
}

.btn-secondary {
  color: $secondary-color;
  background-color: $bg-block;
  border-color: $bg-block;
  border-radius: 500px;
  padding: 3px 8px;
  margin: 5px;
  box-shadow: none;
  &:hover, &:focus {
    color: $secondary-color;
    background-color: #f8f8f8;
    border-color: $bg-block;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  }
  &:not(:disabled):not(.disabled).active, &:active, &:active:focus {
    color: $inverse-color;
    background-color: $primary-color;
    border-color: $primary-color;
    border-radius: 500px;
  }
}

.btn-primary.disabled, .btn-primary[disabled] {
  background-color: $primary-color;
  border-color: $primary-color;
    &:hover , &:focus {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

.btn-primary--a-venir {
  padding: 5px;
  box-shadow: none;
}

.link-animated {
  @include transition(color, .2s);
  display: inline-block;
  position: relative;
  text-decoration: none;
  &:after {
    @include transition(right .2s);
    content: "\e90c";
    font-family: 'icomoon';
    font-size: 1.2rem;
    right: -18px;
    top: 7px;
    position: absolute;
  }
  &:hover, &:active, &:focus {
     color: $secondary-color;
    text-decoration: none;
    &:after {
      right: -25px;
    }
  }
}

.btn-checkbox-selection {
  @include transition(all .2s);
  background-color: transparent;
  border: none;
  color: $text-color;
  font-family: $font-family-regular;
  font-size: 1.2rem;
  line-height: 18px;
  padding: 0 0 0 25px;
  position: relative;
  &:before {
    @include transition(all .2s);
    content: '';
    position: absolute;
    left: 0; 
    top: 0;
    width: 18px; 
    height: 18px;
    border: 1px solid $grey-secondary;
    background-color: transparent;
    border-radius: 0;
  }
  + .btn-checkbox-selection {
    margin-left: 12px;
  }

  &.selected {
    @include transition(all .2s);
    &:before {
      background-color: $text-color;
      border-color: $text-color;
    }
    &:after {
      content: "\e917";
      font-family: 'icomoon';
      position: absolute;
      top: 0; 
      left: 5px;
      font-size: 0.8rem;
      color: $inverse-color;
    }
  }
  
}

.btn-link {
  color: $text-color;
  font-size: 1.4rem;
  line-height: 18px;
  text-decoration: none;
  i {
    margin-left: 5px;
    vertical-align: baseline;
  }
  &:hover, &:focus {
    color: $secondary-color;
    text-decoration: none;
  }
}

.btn-social {
  @include transition(all 0.2s ease);
  display: inline-block;
  line-height: 66px;
  padding: 0 8px;
  text-align: center;
  text-decoration: none;
  i {
    color: #fff;
    font-size: 5rem;
    vertical-align: middle;
  }
  &:hover, &:focus {
    background-color: #fff;
    text-decoration: none;
    
  }
}

.social-links {
  a {
    text-decoration: none;
  }
}


.btn.disabled, .btn[disabled] {
  opacity: 0.5;
}

.double-btn {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
  .btn {
    margin: 0 5px 15px;
  }
  .form-group {
    margin: 0;
  }
}

.dropdown-menu {
  background-color: $primary-color;
  font-size: 1.4rem;
  border-radius: $border-radius-circle;
  margin-top: 10px;
  min-width: 100px;
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: 0 0;
      transform: rotate(45deg);
      top: -7px;
      left: 50%;
      right: auto;
      background-color: $primary-color;
      }
  .dropdown-item {
    color: $inverse-color;
    text-decoration: none;
    font-family: $font-family-sans-serif;
    line-height: 22px;
    &:hover {
      background-color: transparent;
      opacity: .8;
    }
  }
}

.accordion {
  .card {
    border-radius: 0;
    margin-bottom: 5px;
    .card-header {
      border-radius: 0;
      padding: 0;
    }
  }
  .btn {
    width: 100%;
    text-align: left;
    font-family: $font-family-regular;
    font-size: 2.5rem;
  }
}