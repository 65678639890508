.sale-alert-registration {
  h3 {
    font-size: 2.4rem;
    color: $secondary-color;
    text-transform: uppercase;
  }
  input {
    border-radius: 25px;
  }
  button {
    align-self: flex-start;
  }
  .form-group{
    display: block;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }
}