.item-card {
  @include transition(all .2s ease);
  background-color: $inverse-color;
  border: 1px solid $border-color;
  display: block;
  margin-bottom: 20px;
  color: $text-color;
  //position: relative;
  text-decoration: none;
  figure {
    //border-top-left-radius: $border-radius-base;
    //border-top-right-radius: $border-radius-base;
    //overflow: hidden;
    //position: relative;
    width: 100%;
    //z-index: 1;
    img {
      width: 100%;
    }
  }
  /*@include mq(sm) {
    margin-bottom: 30px;
  }*/
  &:hover,
  &:focus {
    cursor: pointer;
    color: $text-color;
    text-decoration: none;
    .link-animated {
      color: $secondary-color;
      &:after {
        right: -25px;
      }
    }
     box-shadow: 2px 3px 5px rgba(0,0,0,.3);
  }
}

.item-card__content {
  background-color: $inverse-color;
  padding: 10px 10px 20px 10px;
  text-align: center;
}

.item-card__bottom {
  bottom: 40px;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
}

.item-card__content__cat {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-card__content__title {
  display: block;
  //font-family: $font-family-bold;
  font-size: 2rem;
  line-height: 22px;
  margin: 0 0 20px;
  /*@include mq(md) {
    margin: 5px 0 20px 0;
  }*/
}

.item-card__content__subtitle {
  color: $text-color;
  display: block;
  font-size: 1.6rem;
  line-height: 22px;
  margin: 0 0 10px;
}

.item-card__content__date {
  color: $grey-secondary;
  display: block;
  font-size: 1.2rem;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-card-view {
  img {
    margin: 0 auto 20px auto;
  }
  .youtubeWrapper {
    margin-bottom: 20px;
  }
}

.item-card--news {
  .item-card--news__content {
    .item-card--new__content__title {
      font-size: 2rem;
      margin: 0 0 8px 0;
    }
    .item-card--new__content__text {
      color: $grey-secondary;
      font-size: 1.2rem;
    }
  }
}
/*
.item-card--service {
  padding-bottom: 50px;
  h3 {
    font-size: 2.2rem;
    line-height: 28px;
    margin-top: 20px;
  }
  .item-card__content {
    padding: 10px 20px 20px 20px;
  }
}

.item-card--job {
  text-align: left;
  .item-card__content {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    padding-bottom: 20px;
    text-align: left;
    .item-card__content__title {
      font-size: 1.8rem;
      margin: 5px 0 5px 0;
    }
    .item-card__content__subtitle {
      margin-bottom: 15px;
    }
    .label {
      margin-bottom: 12px;
    }
  }
}
*/