.breadcrumb {
  background-color: $bg-block;
  list-style-type: none;
  line-height: normal;
  padding: 5px 0;
  margin-bottom: 0;
  > li {
    display: inline-block;
    font-size: 1.2rem;
    font-family: $font-family-sans-serif;
    line-height: 16px;
    margin-bottom: 0;
    position: relative;
    &:before {
      content: none;
    }
    a {
      text-decoration: none;
      &:hover, &:focus {
        color: $secondary-color;
        text-decoration: underline;
      }
    }

    span {
      padding-left: 14px;
    }
    + li {
      &:before {
        content: "\e90d";
        font-family: 'icomoon';
        font-size: 0.8rem;
        left: 3px;
        padding: 0;
        position: absolute;
        top: 1px;
      }
    }
  }
  .separator {
    display: none;
  }
}

